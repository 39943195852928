import React from 'react';

//tpo import
import tpoimg1 from './tpo/logoss.jpg'; 
import tpoimg2 from './tpo/cover.jpg'; 
import tpoimg3 from './tpo/brochure.jpg'; 
import tpoimg4 from './tpo/lowerthird.gif'; 
import tpoimg5 from './tpo/socialtemplat.jpg'; 
import tpoimg6 from './tpo/rollup.jpg'; 
import tpoimg7 from './tpo/splash.png'; 

//tnc import
import tncimg1 from './transcorp/transcorp-01.jpg'; 
import tncimg2 from './transcorp/transcorp-02.jpg'; 
import tncimg3 from './transcorp/transcorpfill-01.jpg'; 
import tncimg4 from './transcorp/transcorpfill-02.jpg'; 
import tncimg5 from './transcorp/Powericon.jpg'; 
import tncimg7 from './transcorp/tncbuilding.jpg'; 
import tncimg6 from './transcorp/sublogos-01.jpg'; 
import tncimg8 from './transcorp/sublogos-02.jpg'; 
import tncimg9 from './transcorp/website.jpg'; 


//mentorish import
import img1 from './mentorish/metorishImage1-01.jpg'; 
import img2 from './mentorish/metorishImage3-01.jpg'; 
import img3 from './mentorish/metorishImage2-01.jpg'; 
import img4 from './mentorish/mentorishsweater.jpg'; 
import img5 from './mentorish/metorishImage4-02.jpg'; 
import img7 from './mentorish/metorishImage4-03.jpg'; 
import img6 from './mentorish/mentorishshirtandbag.jpg'; 
import img8 from './mentorish/mentorishcups.jpg'; 
import img9 from './mentorish/mentorishbag.jpg'; 
import img10 from './mentorish/mentorishshirts.jpg'; 

//star wears import
import appImage1 from './appscreens/appSeveral.jpg'; 
import appImage2 from './appscreens/appLogo-01.jpg'; 
import appImage3 from './appscreens/appLogo-02.jpg'; 
import appImage4 from './appscreens/appFlow.png'; 
import appImage5 from './appscreens/appFrame.png'; 
import appImage6 from './appscreens/appDesigns.png'; 
import appImage7 from './appscreens/appcoveer.jpg'; 


//star wears import
import genImage1 from './genAI/Perfect_Binding_Brochure_Mockup_3.jpg'; 
import genImage2 from './genAI/Perfect_Binding_Brochure_Mockup_5.jpg'; 
import genImage3 from './genAI/realphoto.jpg'; 

import myPdf from './genAI/genAIreport.pdf';



const tpoImages = [
  { src: tpoimg1, alt: 'nAN Descript' },
  { src: tpoimg2, alt: 'nAN Descript' },
  { src: tpoimg3, alt: 'nAN Descript' },
  { src: tpoimg4, alt: 'nAN Descript' },
  { src: tpoimg5, alt: 'nAN Descript' },
  { src: tpoimg6, alt: 'nAN Descript' },
  { src: tpoimg7, alt: 'nAN Descript' },
  // ...more images
];


const tncImages = [
  { src: tncimg5, alt: 'nAN Descript' },
  {designTitle: 'Designing the Logo'},
  {designDescription: `The brand icon is based around the universal icon for power, 1 and 0, on and off, but with the 1 lite up in orange to indicate the switch in this case is always on. The branding concept reflects the brand's commitment to lighting up every industry they diversify into, hence the power icon. The rounded nature of the icon was reflected further in the design on the wordmark`},
  { src: tncimg2, alt: 'nAN Descript' },
  { src: tncimg4, alt: 'nAN Descript' },
  { src: tncimg1, alt: 'nAN Descript' },
  { src: tncimg3, alt: 'nAN Descript' },
  { src: tncimg7, alt: 'nAN Descript' },
  {extraIntro: 'The Subsidiaries'},
  {extrDescription: `As explained earlier, Transcorp operates with a branded house strategy at its core, enhancing its subsidiaries' market presence through the power of its overarching brand, while still allowing each entity the space to innovate and excel within its domain. With this in mind, the subsidiaries needed to look like the parent brand to ensure there is a visual recognition that easily ties them back to the parent brand.`},
  { src: tncimg6, alt: 'nAN Descript' },
  { src: tncimg8, alt: 'nAN Descript' },
  {extraIntro: 'Website Refresh'},
  {extrDescription: `An extended aspect of the brand refresh was the redesign of the transcorp group website, which i took on as a UX project. you can visit the website via the link below`},
  { src: tncimg9, alt: 'nAN Descript' },
  // ...more images
];

const mentorishImages = [
  { src: img4, alt: 'nAN Descript' },
  {designTitle: 'Designing the Logo'},
  { src: img1, alt: 'nAN Descript' },
  {designDescription: `Embarking on the quest to craft Mentor-ish's logo, I found inspiration in the visualisation of mentorship through a sketch drawn by my cousin Jayla, whom I was mentoring. Her depiction of mentorship—as a taller figure guiding a shorter one—captured the essence of guidance and support. This concept became the cornerstone of the logo design. By refining Jayla's sketch and integrating the letter M from the brand name, the final logo design emerged as a minimalist yet meaningful symbol. It seamlessly represented Mentor-ish's mission, striking the perfect balance between a professional appearance and the fundamental idea of mentorship, effectively communicating the dedication to guiding and supporting others at a glance.`},
  { src: img2, alt: 'nAN Descript' },
  { src: img3, alt: 'nAN Descript' },
  { src: img5, alt: 'nAN Descript' },
  {extraIntro: 'Beyond the Logo'},
  {extrDescription: `Beyond the initial logo request, I took it upon myself to craft a minimal brand identity guide for Mentor-ish. This guide encompassed vital elements like color palettes, typography, and usage examples for merchandise to ensure consistency and professionalism across all Mentor-ish communications.`},
  { src: img7, alt: 'nAN Descript' },
  { src: img6, alt: 'nAN Descript' },
  { src: img8, alt: 'nAN Descript' },
  { src: img9, alt: 'nAN Descript' },
  { src: img10, alt: 'nAN Descript' },

  // ...more images
];

const starWearImages = [
  { src: appImage7, alt: 'nAN Descript' },
  {designTitle: 'Logo Design'},
  {designDescription: `The Star Wears logo needed to resonate with the fashion-forward essence of the app. The challenge was to craft something minimal yet instantly recognizable, a symbol that spoke to the brand’s core of fashion and celebrity. Drawing inspiration from the sleek and elegant brand initials style of logos that dominate luxury fashion, the final logo design was made using the S and W, beatifully crafted together to create a single element, complementing the app's overall aesthetic and minimal look and feel.`},
  { src: appImage2, alt: 'nAN Descript' },
  { src: appImage3, alt: 'nAN Descript' },
  {extraIntro: 'Crafting the User Experience'},
  {extrDescription: `The journey began with mapping out a user flow that felt intuitive. Every swipe, tap, and transition had to lead the user naturally through the auction process, from discovery to purchase. Wireframing laid the groundwork, outlining how users would navigate the app, while the final design brought the vision to life with a focus on minimalism and elegance.`},
  { src: appImage4, alt: 'nAN Descript' },
  { src: appImage5, alt: 'nAN Descript' },
  {extrDescription: `The research phase was done by the founder and his team, who delved into understanding the market, the potential users, and the competitive landscape. With surveys and deep market analysis, they provided a rich dataset that became the backbone of our design process. Armed with these insights, I was able to make informed decisions, crafting a user flow that was not just intuitive but also resonated with the target audience's needs and preferences. This process ensured that the design of Star Wears was firmly rooted in real-world data, guiding every aspect of the app's features, layout, and navigation to create a user experience that was both engaging and seamless.`},
  { src: appImage6, alt: 'nAN Descript' },
  { src: appImage1, alt: 'nAN Descript' },
  // ...more images
];

const genImages = [
  { src: genImage1, alt: 'nAN Descript' },
  { src: genImage2, alt: 'nAN Descript' },
  { src: genImage3, alt: 'nAN Descript' },
];

const metorishIntro = {
  introTitle: 'Introduction and Understanding the Vision',
  introDescription: `When Mentor-ish, a group of African students dedicated to mentoring their peers through knowledge sharing, reached out for branding assistance, it was through a friend's introduction that I first learned of their admirable mission. Operating without a website or social media due to budget constraints, Mentor-ish's core operation is rooted in their physical network, utilizing WhatsApp and Telegram groups as their primary communication channels. Inspired by their commitment to fostering growth and development freely among their colleagues, I was compelled to contribute to their cause.
                      Mentor-ish's vision was clear from our initial interviews:`,
  introQuote: ' "they needed a logo that encapsulated the essence of mentorship in a glance, reflecting their principle of experienced individuals guiding the less experienced. They desired a design that was minimalistic yet powerful in conveying their mission, a symbol that would become synonymous with growth and guidance" ',
  extraIntro: 'Beyond the Logo',
  extrDescription: `Your description for image 1 goes here...`,
}

const caseStudies = [
    {
      id: 1,
      title: 'The Politics Of 2024 — Brand Campaign and Event Design',
      category: 'HOLISTIC CAMPAIGN',
      imageUrl: 'path/to/image1.jpg',
      description: 'Detailed description here...',
      year: '2024',
      pageContent: (
        <>
            {/* You can include JSX directly if needed */}
            <section className="bg-dark min-h-screen text-white pb-24">              
                {/* headers and buttons */}
                    <div className="grid lg:grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                        <div className="px-10 sm:px-20 lg:px-24 pt-32 xl:pt-44 lg:pt-32 md:pt-32 ">
                            <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">CASE STUDY</h3>
                            <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl font-bold mb-16  tracking-tighter leading-tight">The Politics Of 2024 - Brand Campaign</h1>
                            <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">MY ROLE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  Creative Direction
                              </li>
                              <li>
                                  Branding
                              </li>
                              <li>
                                  Motion Design
                              </li>
                              <li>
                                  Email Design
                              </li>
                              <li>
                                  Graphic Design
                              </li>
                          </ul>
                          <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">YEAR</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  2024
                              </li>
                            </ul>
                          <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">PROJECT TYPE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  Holistic Campaign
                              </li>
                            </ul>
                        </div>
                        <div className="xl:col-span-2 px-10 sm:px-20 xl:px-60 lg:px-20 md:px-20 pt-10 xl:pt-48 lg:pt-32 leading-7 ">
                            <p>
                            For the "The Politics of 2024" campaign, I drew inspiration from the intricate world where politics, business, and policymaking intersect—a realm expertly navigated by organizations that specialize in guiding companies through the complex global policy landscape. Such advisory firms play a crucial role in helping businesses anticipate, adapt to, or shape policy and regulatory changes. They offer a gamut of services, from portfolio and transaction due diligence support to strategic planning around policy shifts, leveraging their deep understanding of political and regulatory environments to secure their clients' interests and navigate challenges.

                            <br/>
                            <br/>The creative process for "The Politics of" brand aimed to visually encapsulate the essence of navigating these complexities. Through various iterations, we settled on a design theme using lines to symbolize the flow and organization of subjects that initially appear chaotic. This motif effectively represents the brand's mission to provide clarity and direction in the often turbulent intersection of global policies and business strategies. The theme was then seamlessly integrated across all communication channels—emails, brochures, social media, event landing pages, event location branding, and videos—ensuring a cohesive and impactful brand presence.

                            <br/>
                            <br/>The campaign was met with overwhelming success, earning accolades from attendees and team members alike. It was a testament to the power of visual communication in conveying the brand's core mission and the essential service it provides to businesses operating in a globalized world.
                            </p>
                        </div>
                    </div>
                    
            </section>

            <section className="bg-dark py-8 px-10">
              <div style={{ padding: '75% 0 0 0', position: 'relative', width: '100%' }}>
                <iframe 
                  src="https://player.vimeo.com/video/920140073?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="brand_refresh_presentation"
                  allowFullScreen> {/* Note the addition of allowFullScreen for fullscreen support */}
                </iframe>
              </div>
              
              <div className="md:px-10 sm:px-1 px-1 ">
                <div className="grid grid-cols-1 gap-4">
                  {tpoImages.map((image, index) => (
                    <div key={index} className="w-full">
                      <img
                        src={image.src}
                        alt={image.alt}
                        className="w-full h-auto"
                      />
                    </div>
                  ))}
                </div >

                <div className=' mb-16'>
                        <a href='https://events.global-counsel.com/thepoliticsof2024' target='_blank' rel='noopener noreferrer' className='mb-10'>
                         <button className=" w-full border-2 px-4 border-myCyan py-2 text-sm font-inter font-bold text-myCyan sm:px-8 sm:py-4 sm:text-base hover:bg-myCyan hover:text-black">visit event page</button>
                        </a>
                </div>
              </div>
              <div style={{ padding: '75% 0 0 0', position: 'relative', width: '100%' }}>
                    <iframe 
                      src="https://player.vimeo.com/video/920140598?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      title="brand_refresh_presentation"
                      allowFullScreen> {/* Note the addition of allowFullScreen for fullscreen support */}
                    </iframe>
              </div>
              <div style={{ padding: '75% 0 0 0', position: 'relative', width: '100%' }}>
                <iframe 
                  src="https://player.vimeo.com/video/920142565?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="brand_refresh_presentation"
                  allowFullScreen> {/* Note the addition of allowFullScreen for fullscreen support */}
                </iframe>
              </div>
            </section>


          
            <section className="bg-dark text-white pt-24   px-10">
              <div className=" flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 ">
                <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">PROJECT IMPACT</h3>
                <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold   tracking-tighter leading-tight text-center">The campaign was a huge success, there was visual consistency across board, the branding was implemented across all communication network and templates were made to fasttrack the output of the marketing team. <span><a href='https://www.global-counsel.com/sites/default/files/2024-01/Brochure-politics%20of%202024%20vf.pdf' className=' text-myCyan'>read report</a></span></h1>
              </div>
            </section>
        </>
      ),
      // Add more fields as necessary
    },
    {
        id: 2,
        title: 'Transcorp Group — Brand Refresh and Subsidiary Branding',
        category: 'BRAND REFRESH',
        imageUrl: 'path/to/image1.jpg',
        description: 'Detailed description here...',
        year: '2022',
        pageContent: (
          <>
              {/* You can include JSX directly if needed */}
              <section className="bg-dark min-h-screen text-white pb-24">              
                  {/* headers and buttons */}
                      <div className="grid lg:grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                          <div className="px-10 sm:px-20 lg:px-24 pt-32 xl:pt-44 lg:pt-32 md:pt-32 ">
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">CASE STUDY</h3>
                              <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl font-bold mb-16  tracking-tighter leading-tight">Transcorp Group — Brand Refresh and Subsidiary Branding</h1>
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">MY ROLE</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    Creative Direction
                                </li>
                                <li>
                                    Branding
                                </li>
                            </ul>
                            <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">YEAR</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    2022
                                </li>
                              </ul>
                              <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">PROJECT TYPE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  Rebrand
                              </li>
                            </ul>
                          </div>
                          <div className="xl:col-span-2 px-10 sm:px-20 xl:px-60 lg:px-20 md:px-20 pt-10 xl:pt-48 lg:pt-32 leading-7 ">
                              <p>
                                Transcorp Group, Nigeria's premier conglomerate, stands as a towering figure in the nation's economic landscape. With a vast portfolio that spans the Power, Hospitality, and Energy sectors, Transcorp's influence touches the lives of millions, driving progress and fostering innovation. Among its portfolio are Transcorp Power, Transafam Power, Transcorp Hotels, Transcorp Energy, and Aura by Transcorp Hotels—a constellation of subsidiaries that reflect the Group's dynamic approach to business as a branded house.

                              <br/> <br/>Embarking on a journey to refresh Transcorp's brand identity, the task was not just about modernizing a logo but encapsulating the essence of a conglomerate that has become synonymous with excellence and growth. The Group wanted to maintain its core brand colors, fonts, and assets signified a respect for its heritage, only desiring an update to the logo which has stood the test of time.

                              <br/> <br/>The challenge was to consolidate the look and feel of Transcorp's subsidiaries into a cohesive brand narrative, ensuring each entity's logo resonates with the Group's overarching mission of improving lives and transforming Nigeria while retaining its unique identity. This delicate balance of unity and distinction was key to reinforcing Transcorp's position as a "branded house," a conglomerate whose strength lies in its diversity and synergy.

                              <br/> <br/>The rebranding process was a deep dive into the heart of what Transcorp stands for—innovation, leadership, and commitment to excellence. The brand according to its mission light up every industry they delve into. The new logo design is a testament to these values, a visual representation that is both contemporary and timeless. By refining the logos of Transcorp's subsidiaries, I ensured a visual harmony that ties back to the parent brand, fostering a stronger, more unified corporate identity.
                            </p>
                          </div>
                      </div>
                      
              </section>
  
              <section className="bg-dark px-10">
                <div className="md:px-5 sm:px-0 px-0 ">
                  <div className="grid grid-cols-1 gap-1">
                  {tncImages.map((image, index) => (
                    <React.Fragment key={index}>
                      <div className="w-full">
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="w-full h-auto mx-0"
                        />
                      </div>
                      <div className="pt-0 xl:pt-5 lg:pt-5 leading-7">
                        <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold text-white  tracking-tighter leading-tight '>{image.designTitle}</h1>
                        <p className=' text-white pb-5'>{image.designDescription}</p>
                      </div>
                      <div className="pt-1 xl:pt-1 lg:pt-1 leading-7">
                        <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold text-white  tracking-tighter leading-tight '>{image.extraIntro}</h1>
                        <p className=' text-white pb-10'>{image.extrDescription}</p>
                      </div>
                      <div className="pt-1 xl:pt-1 lg:pt-1 leading-7">
                        <p className=' text-white pb-10'>{image.extrDescription}</p>
                      </div>
                    </React.Fragment>
                  ))}
                        <div style={{ padding: '75% 0 0 0', position: 'relative', width: '100%' }}>
                          <iframe 
                            src="https://player.vimeo.com/video/918966290?badge=0&autopause=0&player_id=0&app_id=58479"
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            title="brand_refresh_presentation"
                            allowFullScreen> {/* Note the addition of allowFullScreen for fullscreen support */}
                          </iframe>
                        </div>
                        <a href='http://www.transcorpgroup.com' target='_blank' rel='noopener noreferrer'>
                         <button className=" w-full border-2 px-4 border-myCyan py-2 text-sm font-inter font-bold text-myCyan sm:px-8 sm:py-4 sm:text-base hover:bg-myCyan hover:text-black">visit website</button>
                        </a>
                  </div>           
                </div>
              </section>
  
  
            
              <section className="bg-dark text-white pt-24   px-10">
                <div className=" flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 ">
                  <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">PROJECT IMPACT</h3>
                  <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold tracking-tighter leading-tight text-center"> The rebrand was implemented across the group giving the brand a refreshed look and visually positioning the brand firmly as the branded house it is through the consolidation and synergy of the it's company portfolio.</h1>
                </div>
              </section>
          </>
        ),
        // Add more fields as necessary
      },
      {
        id: 3,
        title: 'Mentorish — Brand Logo Design',
        category: 'BRANDING',
        imageUrl: 'path/to/image1.jpg',
        description: 'Detailed description here...',
        year: '2021',
        pageContent: (
          <>
              {/* You can include JSX directly if needed */}
              <section className="bg-dark min-h-screen text-white pb-24">              
                  {/* headers and buttons */}
                      <div className="grid lg:grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                          <div className="px-10 sm:px-20 lg:px-24 pt-32 xl:pt-44 lg:pt-32 md:pt-32 ">
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">CASE STUDY</h3>
                              <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl font-bold mb-16  tracking-tighter leading-tight">Mentorish — Brand Logo Design</h1>
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">MY ROLE</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    Creative Direction
                                </li>
                                <li>
                                    Branding
                                </li>
                            </ul>
                            <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">YEAR</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    2023
                                </li>
                              </ul>
                              <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">PROJECT TYPE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  Branding
                              </li>
                              <li>
                                  free - Pro Bono
                              </li>
                            </ul>
                          </div>
                          <div className="xl:col-span-2 px-10 sm:px-20 xl:px-60 lg:px-20 md:px-20 pt-10 xl:pt-48 lg:pt-32 leading-7 ">
                              <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold mb-4 text-white  tracking-tighter leading-tight'> {metorishIntro.introTitle} </h1>
                              <p>
                                {metorishIntro.introDescription}
                                <br/> <br/> <span className=' xl:text-3xl lg:text-2xl md:text-2xl sm:text-xl text-xl font-medium italic tracking-tight leading-tight text-center'> {metorishIntro.introQuote}</span>.
                              </p>
                          </div>
                      </div>
                      
              </section>
  
              <section className="bg-dark px-10">
                <div className="md:px-5 sm:px-0 px-0 ">
                  <div className="grid grid-cols-1 gap-1">
                  {mentorishImages.map((image, index) => (
                    <React.Fragment key={index}>
                      <div className="w-full">
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="w-full h-auto mx-0"
                        />
                      </div>
                      <div className="pt-0 xl:pt-5 lg:pt-5 leading-7">
                        <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold text-white  tracking-tighter leading-tight '>{image.designTitle}</h1>
                        <p className=' text-white pb-5'>{image.designDescription}</p>
                      </div>
                      <div className="pt-1 xl:pt-1 lg:pt-1 leading-7">
                        <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold text-white  tracking-tighter leading-tight '>{image.extraIntro}</h1>
                        <p className=' text-white pb-10'>{image.extrDescription}</p>
                      </div>
                    </React.Fragment>
                  ))}
                  </div>
                </div>
              </section>
  
  
            
              <section className="bg-dark text-white pt-24   px-10">
                <div className=" flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 ">
                  <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">FOUNDER TESTIMONIAL</h3>
                  <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold   tracking-tighter leading-normal text-center">I'm thrilled with our new logo, the thought behind it and the journey to create it, many thanks to Jeff for perfectly capturing our mission and helping us decide on our new tagline which was based on the concept of the logo.</h1>
                </div>
              </section>
          </>
        ),
        // Add more fields as necessary
      },
      {
        id: 4,
        title: 'Regulating Generative AI — An Industry Report',
        category: 'TYPE DESIGN',
        imageUrl: 'path/to/image1.jpg',
        description: 'Detailed description here...',
        year: '2023',
        pageContent: (
          <>  
              {/* You can include JSX directly if needed */}
              <section className="bg-dark min-h-screen text-white pb-24">              
                  {/* headers and buttons */}
                      <div className="grid lg:grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                          <div className="px-10 sm:px-20 lg:px-24 pt-32 xl:pt-44 lg:pt-32 md:pt-32 ">
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">CASE STUDY</h3>
                              <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl font-bold mb-16  tracking-tighter leading-tight">Regulating Generative AI — An Industry Report</h1>
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">MY ROLE</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    Creative Direction
                                </li>
                                <li>
                                    Illustration
                                </li>
                                <li>
                                    Type Design
                                </li>
                                <li>
                                    Iconography
                                </li>
                                <li>
                                    Data Visualisation
                                </li>
                            </ul>
                            <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">YEAR</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    2023
                                </li>
                              </ul>
                              <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">PROJECT TYPE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  Type Design
                              </li>
                            </ul>
                          </div>
                          <div className="xl:col-span-2 px-10 sm:px-20 xl:px-60 lg:px-20 md:px-20 pt-10 xl:pt-48 lg:pt-32 leading-7 ">
                              <p>When I began designing the "Regulating Generative AI" report for Global Counsel, my goal was to produce a powerful visual document that explained the complicated issues around AI legislation. Using Adobe InDesign, I methodically created a layout that matched the complexity of the information, combining dynamic infographics, strategic typography, and data visualisation approaches. This method attempted to summarise high-level research findings into an entertaining, understandable style.  I ensured that each element—color schemes, chart designs, and iconography were part of communicating the core message of the generative AI Report, allowing for a narrative flow that attracted and enlightened readers. This design journey not only enhanced the report's visual appeal, but also highlighted its value as a crucial resource in the conversation on AI policy-making.
                                <br/><br/>This was a straight forward project, and was only featured to showcase a published type design project i worked on, so i won't bore you further with extra details. 
                              </p>
                          </div>
                      </div>
                      
              </section>
  
              <section className="bg-dark py-8 px-10">
                <div className="md:px-10 sm:px-1 px-1">
                  <div className="grid grid-cols-1 gap-4">
                    {genImages.map((image, index) => (
                      <div key={index} className="w-full">
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="w-full h-auto"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full xl:px-10 md:px-10 mt-5"> {/* Ensure full width */}
                  <iframe
                    src={myPdf}
                    style={{width: '100%', height: '600px', border: 'none'}}
                    title="PDF Viewer">
                  </iframe>
                </div>
              </section>
  
  
            
              <section className="bg-dark text-white pt-24 px-10">
                <div className=" flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 ">
                  <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">PROJECT SUMMARY</h3>
                  <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold   tracking-tighter leading-tight text-center">The project was executed working hand in hand with the research team to ensure it captured their intentions. The larger team were excited with the final output and digital and print versions were made for distribution</h1>
                </div>
              </section>
          </>
        ),
        // Add more fields as necessary
      },
      {
        id: 5,
        title: 'Star Wears — A used clothes marketplace',
        category: 'UX DESIGN',
        imageUrl: 'path/to/image1.jpg',
        description: 'Detailed description here...',
        year: '2023',
        pageContent: (
          <>
              {/* You can include JSX directly if needed */}
              <section className="bg-dark min-h-screen text-white pb-24">              
                  {/* headers and buttons */}
                      <div className="grid lg:grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                          <div className="px-10 sm:px-20 lg:px-24 pt-32 xl:pt-44 lg:pt-32 md:pt-32 ">
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">CASE STUDY</h3>
                              <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl font-bold mb-16  tracking-tighter leading-tight">Star Wears — A used clothes marketplace</h1>
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">MY ROLE</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    Creative Direction
                                </li>
                                <li>
                                    UX Design
                                </li>
                                <li>
                                    Branding
                                </li>
                            </ul>
                            <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">YEAR</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    2020
                                </li>
                              </ul>
                              <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">PROJECT TYPE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  UX Design
                              </li>
                            </ul>
                          </div>
                          <div className="xl:col-span-2 px-10 sm:px-20 xl:px-60 lg:px-20 md:px-20 pt-10 xl:pt-48 lg:pt-32 leading-7 ">
                              <p>
                                Star Wears, an innovative app concept designed to bridge the gap between celebrities and their fans through fashion. I first crossed paths with the founder at a networking event in late 2019. When he reached out in 2020 to collaborate on this project under a freelance contract, I jumped at the opportunity. The founder's vision was both clear and compelling: to create a marketplace where fans could bid on and purchase clothing once worn by their favorite celebrities. Tasked with bringing this vision to life, I was responsible for designing a user-friendly app prototype. This prototype needed to not only highlight Star Wears' unique value proposition but also captivate potential investors at an upcoming networking event.
                                <br/>
                                <br/>
                                The mission behind Star Wears was both fascinating and challenging. The brand aimed to democratize access to celebrity wardrobes, allowing fans to own a piece of their idol's fashion history. This wasn't just about clothes; it was about bringing fans closer to their idols. The challenge lay in designing an app that could handle the auction process seamlessly, ensuring a user-friendly experience while keeping the glamour and exclusivity of celebrity fashion intact. 
                                <br/>
                                <br/>
                                Note: the userflow, wireframe and high fidelity images are blurred on purpose to protect the data.
                              </p>
                          </div>
                      </div>
                      
              </section>
  
              <section className="bg-dark px-10">
                <div className="md:px-5 sm:px-0 px-0 ">
                  <div className="grid grid-cols-1 gap-1">
                  {starWearImages.map((image, index) => (
                    <React.Fragment key={index}>
                      <div className="w-full">
                        <img
                          src={image.src}
                          alt={image.alt}
                          className="w-full h-auto mx-0"
                        />
                      </div>
                      <div className="pt-0 xl:pt-5 lg:pt-5 leading-7">
                        <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold text-white  tracking-tighter leading-tight '>{image.designTitle}</h1>
                        <p className=' text-white pb-5'>{image.designDescription}</p>
                      </div>
                      <div className="pt-1 xl:pt-1 lg:pt-1 leading-7">
                        <h1 className='lg:text-4xl md:text-4xl sm:text-3xl text-3xl font-bold text-white  tracking-tighter leading-tight '>{image.extraIntro}</h1>
                        <p className=' text-white pb-10'>{image.extrDescription}</p>
                      </div>
                    </React.Fragment>
                  ))}
                  </div>
                </div>
              </section>
  
  
            
              <section className="bg-dark text-white pt-24 px-10">
                <div className=" flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 ">
                  <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">Project Impact</h3>
                  <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold   tracking-tighter leading-tight text-center">The Star Wears project achieved notable success, placing second in its category. However, logistical challenges related to sourcing celebrity outfits led the founder to put the project on hold, aiming for a strategic reassessment to ensure its future viability and success.</h1>
                </div>
              </section>
          </>
        ),
        // Add more fields as necessary
      },
      {
        id: 6,
        title: 'My Demo Reel — A Compilation of some of my animated designs',
        category: 'MOTION GRAPHICS',
        imageUrl: 'path/to/image1.jpg',
        description: 'Detailed description here...',
        year: '2018 - Date',
        pageContent: (
          <>  
              {/* You can include JSX directly if needed */}
              <section className="bg-dark min-h-screen text-white pb-24">              
                  {/* headers and buttons */}
                      <div className="grid lg:grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
                          <div className="px-10 sm:px-20 lg:px-24 pt-32 xl:pt-44 lg:pt-32 md:pt-32 ">
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">CASE STUDY</h3>
                              <h1 className="lg:text-5xl md:text-5xl sm:text-4xl text-4xl font-bold mb-16  tracking-tighter leading-tight">My Demo Reel — A Compilation of some of my animated designs</h1>
                              <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">MY ROLE</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    Creative Direction
                                </li>
                                <li>
                                    Motion Design
                                </li>
                                <li>
                                    Illustration
                                </li>
                            </ul>
                            <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">YEAR</h3>
                              <ul className=" font-inter text-xl leading-8 ">
                                <li>
                                    2023
                                </li>
                              </ul>
                              <h3 className="lg:text-xl md:text-lg mb-4 mt-12 font-Inter font-thin text-myCyan tracking-wider">PROJECT TYPE</h3>
                            <ul className=" font-inter text-xl leading-8 ">
                              <li>
                                  Motion Design
                              </li>
                            </ul>
                          </div>
                          <div className="xl:col-span-2 px-10 sm:px-20 xl:px-60 lg:px-20 md:px-20 pt-10 xl:pt-48 lg:pt-32 leading-7 ">
                              <p>
                                Hey there! Welcome to my demo reel. I've spent most of my career in motion graphics working under the radar due to non-disclosure agreements, which means a lot of the cool stuff I've worked on, I can't always show off. But what you're about to see is a mix of projects I've gotten the green light to share and some personal favorites of mine. <br/> <br/> Since diving into the world of motion graphics back in 2017, I've worked on several projects for different clients across the world through agencies i've worked with. My process is unique and involves script writing, storyboarding, animatic design, sound design and motion design. It is all about bringing that idea to life in the most effective way with the tools and techniques I've mastered over the years.<br/><br/> 
                                while I can't show everything I've worked on, I hope what you see here gives you a good idea of my passion for motion graphics and the creative energy I bring to every project. Enjoy the show!
                              </p>
                          </div>
                      </div>
                      
              </section>
  
              <section className="bg-dark py-8 px-10">
                <div className="md:px-10 sm:px-1 px-1 ">
                  <div className="video-container" style={{ padding: '56.25% 0 0 0', position: 'relative', width: '100%' }}>
                    <iframe 
                      src="https://player.vimeo.com/video/918867827?badge=0&autopause=0&player_id=0&app_id=58479" 
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                      style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                      title="demo reel">
                    </iframe>
                  </div>
                </div>
              </section>  
  
            
              <section className="bg-dark text-white pt-24   px-10">
                <div className=" flex flex-col items-center justify-center px-0 sm:px-1 md:px-10 ">
                  <h3 className="lg:text-xl md:text-lg mb-4 font-Inter font-thin text-myCyan tracking-wider">PROJECT IMPACT</h3>
                  <h1 className="lg:text-5xl md:text-3xl sm:text-2xl text-2xl font-bold   tracking-tighter leading-tight text-center">My motion graphics have consistently delivered impactful and engaging experiences for clients, significantly driving tangible results in marketing, boosting brand visibility and audience engagement.</h1>
                </div>
              </section>
          </>
        ),
        // Add more fields as necessary
      },
    // Add more case studies
  ];
  
  export default caseStudies;
  