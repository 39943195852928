import React  from 'react';
import Spline from '@splinetool/react-spline';
import ToolIcon from './components/ToolIcon';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import ExperienceSection from './components/ExperienceSection';
import { Link } from 'react-router-dom'

import { ReactComponent as LinkedInIcon } from './icons/linkedin.svg';
import { ReactComponent as DribbbleIcon } from './icons/dribbble.svg';
import { ReactComponent as BehanceIcon } from './icons/behance.svg';
import { ReactComponent as PhotoshopIcon } from './icons/photoshop.svg';
import { ReactComponent as IllustratorIcon } from './icons/Illustrator.svg';
import { ReactComponent as IndesignIcon } from './icons/indesign.svg';
import { ReactComponent as AfterEffectsIcon } from './icons/aftereffects.svg';
import { ReactComponent as PremiereProIcon } from './icons/premierepro.svg';
import { ReactComponent as LightRoomIcon } from './icons/lightroom.svg';
import { ReactComponent as AuditionIcon } from './icons/audition.svg';
import { ReactComponent as FigmaIcon } from './icons/figma.svg';
import { ReactComponent as HtmlIcon } from './icons/html.svg';
import { ReactComponent as CssIcon } from './icons/css.svg';
import { ReactComponent as JavaScriptIcon } from './icons/javascript.svg';
import { ReactComponent as ReactIcon } from './icons/react.svg';

import image1 from './pofcover.jpg'
import image2 from './tncCover.jpg'
import image3 from './metorishImage3.jpg'
import image4 from './imagesvg4.jpg'
import image5 from './appcover.jpg'
import image6 from './reelimage.jpg'


export default function HomePage() {

    const experienceItems = [
        {
            id: 1,
            heading: 'GLOBAL COUNSEL',
            title: 'Brand & Visual Design Editor',
            company: 'Remote - UK',
            period: '2022 - Present',
            description: 'As the Brand and Visual Designer at Global Counsel, I developed and maintained the visual identity and strategy for the brand, ensuring consistency across various mediums. My role involved designing campaign and event branding, marketing materials, emails, digital content, as well as product, UX, and motion design. Collaborating with teams, I aligned these creative efforts with the business objectives, staying abreast of trends to keep the brand relevant and highly regarded.',
            // More details here
        },
        {
            id: 2,
            heading: 'NORTHUMBRIA UNIVERSITY',
            title: 'Lead Digital & Content Designer',
            company: 'Remote - UK',
            period: '2021 - 2022',
            description: "My role encompassed overseeing the design and development of compelling digital content, including websites, social media graphics, and multimedia campaigns, ensuring they align with the university's brand standards. I collaborated closely with various departments to understand their needs and crafted visually appealing and informative designs to support their objectives.",
            // More details here
        },
        {
            id: 3,
            heading: 'TRANSCORP GROUP',
            title: 'Group Brand Designer & Creative Director',
            company: 'Hybrid - Lagos',
            period: '2017 - 2021',
            description: "At Transcorp Group, I was responsible for directing the creative process of various projects, including still and motion graphics, website management, and the development of marketing materials like brochures, email campaigns, and annual reports. Additionally, I led initiatives such as directing storytelling documentaries to push the brand's CSR activities, and the Humans of Transcorp initiative that showcased the company as a great place to work, significantly enhancing brand engagement and storytelling.",
            // More details here
        },
        {
            id: 4,
            heading: 'MARTIN MEDIA',
            title: 'Creative Designer',
            company: 'Remote - USA',
            period: '2016 - 2017',
            description: 'My role as a Creative Designer at the agency involved transforming marketing strategies and objectives into coherent creative strategies, ensuring alignment with the company’s goals and developing execution roadmaps. I managed the creative process from concept to completion, including the pitching and implementation of campaign strategies. Additionally, I branded several businesses, devised brand strategy documents, conducted brand audits, and ensured a compliant digital presence.',
            // More details here
        },
        // ... other experience items
        ];
        
    const socialLinks = [
        { Icon: LinkedInIcon, url: "https://www.linkedin.com/in/yourprofile" },
        { Icon: DribbbleIcon, url: "https://dribbble.com/yourprofile" },
        { Icon: BehanceIcon, url: "https://www.behance.net/yourprofile" },
      ];


    //Object Data for case studies
    const caseStudies = [
        {
            id: 1,
            title: 'The Politics Of 2024 — Brand Campaign and Event Design',
            category: 'HOLISTIC CAMPAIGN',
            imageUrl: image1, // Replace with your image path
        },
        {
            id: 2,
            title: 'Transcorp Group — Brand Refresh and Subsidiary Branding',
            category: 'BRAND REFRESH',
            imageUrl: image2, // Replace with your image path
        },
        {
            id: 3,
            title: 'Mentorish — Brand Logo Design',
            category: 'BRANDING',
            imageUrl: image3, // Replace with your image path
        },
        {
            id: 4,
            title: 'Regulating Generative AI — An Industry Report',
            category: 'TYPE DESIGN',
            imageUrl: image4, // Replace with your image path
        },
        {
            id: 5,
            title: 'Star Wears — A used clothes marketplace',
            category: 'UX DESIGN',
            imageUrl: image5, // Replace with your image path
         },
         {
            id: 6,
            title: 'My Demo Reel — A Compilation of some of my animated designs',
            category: 'MOTION DESIGN',
            imageUrl: image6, // Replace with your image path
        },
        // ... Add other case studies here
      ];

    const toolsData = [
        { Icon: PhotoshopIcon, name: 'Photoshop' },
        { Icon: IllustratorIcon, name: 'Illustrator' },
        { Icon: IndesignIcon, name: 'Indesign' },
        { Icon: AfterEffectsIcon, name: 'After Effects' },
        { Icon: PremiereProIcon, name: 'Premiere Pro' },
        { Icon: LightRoomIcon, name: 'Lightroom' },
        { Icon: AuditionIcon, name: 'Audition' },
        { Icon: FigmaIcon, name: 'Figma' },
        { Icon: HtmlIcon, name: 'HTML' },
        { Icon: CssIcon, name: 'CSS' },
        { Icon: JavaScriptIcon, name: 'JavaScript' },
        { Icon: ReactIcon, name: 'React' },
        // ... other tools
      ];


    
    

  return (
    <>

        {/* hero section */}
        <section className="relative bg-gradient-to-b from-navy to-black min-h-screen text-white pb-24">  

            {/* Placeholder for your Spline animation */}
                {/* Placeholder for your Spline animation, filling the section */}
            <div className="absolute z-10 w-full h-full">
                 <Spline scene="https://prod.spline.design/i3dCHnX0eZAqvDY2/scene.splinecode" />
            </div>
        
            {/* headers and buttons */}
            <div className="flex flex-col lg:grid lg:grid-cols-3 lg:gap-4 absolute bottom-24 z-30 mx-10 sm:mx-20 lg:mx-64">

                {/* main div */}
                <div className="lg:col-span-2 pr-0 lg:pr-12"> 
                    <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">JEFFREY A PRYME</h3>
                    <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold mb-6 tracking-tighter leading-tight">Multi-discipline Brand and Visual Designer</h1>
                    <h2 className="lg:text-2xl md:text-2xl  mb-9 pr-12 tracking-tight font-light">I specialize in crafting compelling brand stories, building influential brands through a diverse array of visuals, and positioning businesses for success.</h2>
                    <Link to="/about">
                        <button className="border-2 px-4 border-myCyan py-2 text-sm font-inter font-bold text-myCyan sm:px-8 sm:py-4 sm:text-base hover:bg-myCyan hover:text-black">About Me</button>
                    </Link>
                </div>

                {/* Empty div for spacing on large screens */}
                <div className="hidden lg:block"></div>
            </div>
            

            {/* neon email on the left */}
            <div className="hidden lg:block absolute left-4 bottom-32 transform -rotate-90 transform-origin-bottom-left font-inter font-light text-myCyan">
                <span className=" tracking-ultraTight mr-2">-------------</span>
                <span>prymestudio@gmail.com</span>
            </div>

                {/* social icons */}
            <div className="hidden lg:block absolute right-4 bottom-24 px-12 z-20">
                <div className=' space-y-2'>
                {socialLinks.map(({ Icon, url }, index) => (
                    <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="block text-dark hover:text-myCyan">
                        <Icon className="h-6 w-6" /> {/* Adjust the size as needed */}
                    </a>
                ))}
                </div>
            </div>



        </section>

        {/* Experience section */}
        <section className="bg-dark text-white py-24">
            <div className="px-10 sm:px-20 lg:px-24  mt-1 md:mt-4 lg:mt-10">
                <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">WHERE I’VE WORKED</h3>
                <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold mb-24  tracking-tighter leading-tight">My Experience</h1>
            </div>
            <ExperienceSection experienceItems={experienceItems} />
        </section>

        {/* Case Studies Section */}
        <section className="bg-gradient-to-b from-black to-navy py-24">
            <div className="px-10 sm:px-20 lg:px-24  mt-1 md:mt-4 lg:mt-10">
                <div className="container mx-auto px-4">
                    <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">FEATURED PROJECTS</h3>
                    <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold mb-24  tracking-tighter leading-tight text-white">Case Studies</h1>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 lg:gap-8 md:gap-4 gap-4">
                        {caseStudies.map((study) => (
                            <Link to={`/case-study/${study.id}`} key={study.id} className="case-study-card-link relative hover:bg-gradient-to-t hover:from-myCyan hover:via-transparent hover:to-transparent hover:opacity-90 hover:transform hover:scale-x-105 hover:origin-center text-white hover:text-black">
                                <div className="case-study-card overflow-hidden ">
                                    <div className="absolute inset-0 bg-gradient-to-b from-navy via-transparent to-transparent opacity-85 hover:bg-myCyan hover:opacity-40"></div>
                                        <img src={study.imageUrl} alt={study.title} />
                                        <div className="absolute top-0 left-0 p-10">
                                            <h3 className=" text-sm md:text-base lg:text-base xl:text-xl uppercase tracking-widest pb-2">{study.category}</h3>
                                            <h1 className="xl:text-4xl lg:4xl md:text-3xl sm:text-3xl text-3xl font-bold mb-24 tracking-tighter leading-tight">{study.title}</h1>
                                        </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                   
                </div>
            </div>
        </section>

        {/* tools i use section */}  
        <section className=" bg-gradient-to-b from-navy to-black py-10 lg:pb-20">
            <div className="px-10 sm:px-20 lg:px-24  mt-1 md:mt-4 lg:mt-10">
                <h3 className="lg:text-2xl md:text-xl mb-4 font-Inter text-lightCyan tracking-wider">PROFICIENCY</h3>
                <h1 className="lg:text-7xl md:text-6xl sm:text-5xl text-5xl font-bold mb-5 sm:mb-6 md:mb-12 lg:mb-24  tracking-tighter leading-tight text-white">My Tools</h1>
                <div className="grid grid-cols-8 gap-3">
                {toolsData.map((tool, index) => (
                    <div key={index} className="col-span-1 p-2 sm:p-2 md:p-4 lg:p-8 bg-dark-500 rounded transition-colors duration-300  border border-navy hover:border-transparent hover:bg-myCyan ">
                    <ToolIcon icon={tool.Icon} name={tool.name} />
                    </div>
                ))}
                </div>
            </div>
        </section>

        {/* call to action */}
        <CallToAction />

        {/* Footer */}
        <Footer />

    </>
  );
}